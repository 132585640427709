



























import Vue from 'vue';
import PageTemplate from '@/components/templates/pageTemplate/PageTemplate.vue';
import AppButton from '@/components/atoms/appButton/AppButton.vue';
import { ButtonCharacter } from '@/enums/buttonCharacter';
import { ButtonTheme } from '@/enums/buttonTheme';

export default Vue.extend({
    name: 'ThankYouTemplate',
    components: {
        PageTemplate,
        AppButton,
    },
    props: {
        pageContent: {
            type: Object,
            required: true,
        },
    },
    computed: {
        buttonCharacterLaquo() {
            return ButtonCharacter.laquo;
        },
        buttonThemeBackground() {
            return ButtonTheme.buttonLight;
        },
    },
    methods: {
        handleButtonNewApplicationClick(): void {
            this.$emit('button-new-application-click');
        },
        handleButtonBackToOverviewClick(): void {
            this.$emit('button-back-to-overview-click');
        },
    },
});
