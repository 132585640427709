















import Vue from 'vue';
import ThankYouTemplate from '@/components/templates/thankYouTemplate/ThankYouTemplate.vue';
import { contentServices } from '@/services/contentService';
import SpinnerArea from '@/components/molecules/spinnerArea/SpinnerArea.vue';
import { SpinnerState } from '@/enums/spinnerState';
import { MessageType } from '@/enums/messageType';
import { dialogueMessageService } from '@/services/dialogueMessageService';
import { PageContent } from '@/api/interfaces/content/app/pageContent';
import router from '@/router';

export default Vue.extend({
    name: 'ThankYou',
    components: {
        ThankYouTemplate,
        SpinnerArea,
    },
    data: () => {
        return {
            spinner: SpinnerState.off,
            thankYouContent: null as PageContent | null,
        };
    },
    created() {
        if (this.$route.query.code === 'unauthorized') {
            dialogueMessageService.show('unauthorized', MessageType.error);
        }
    },
    async mounted() {
        this.spinner = SpinnerState.opaque;

        const content = await contentServices.app.requestContentOnce();
        this.thankYouContent = content.pages.thankYouContent;

        this.spinner = SpinnerState.off;
    },
    methods: {
        handleButtonNewApplicationClick(): void {
            router.push('/antrag');
        },
        handleButtonBackToOverviewClick(): void {
            router.push('/');
        },
    },
});
